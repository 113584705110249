<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useWindowStore } from '@src/store/window'
import { useAuthStore } from '@src/store/auth.ts'
import Validator, { Yup } from '@src/validator.ts'
import { api } from '@src/api'
import FormText from '@src/components/form/FormText.vue'
import Plans from '@src/components/Plans.vue'
import Terms from '@src/components/Terms.vue'
import FormCheckbox from '@src/components/form/FormCheckbox.vue'
import BnrFree from '@src/components/BnrFree.vue'

const router = useRouter()
const windowStore = useWindowStore()

const googleLogin =
  (import.meta.env.VITE_BASE_URL ?? 'https://papaloves.catscode.ru') +
  '/auth/google/callback'
const appleLogin =
  (import.meta.env.VITE_BASE_URL ?? 'https://papaloves.catscode.ru') +
  '/auth/apple'

const isLoading = ref(false)
const step = ref(1)
const registrationToken = ref('')
const isCodeSent = ref(false)

const validator = new Validator()
const name = ref('')
const surname = ref('')
const email = ref('')
const emailCode = ref('')
const password = ref('')
const repeatPassword = ref('')
// TODO: Temporary disable TP control
// const chooseTp = ref<number | null>(null)
const chooseTp = ref<number | null>(2)
const agreeTerms = ref(false)
const termsShow = ref(false)

const finishStep1 = () => {
  if (!isLoading.value) {
    isLoading.value = true

    validator.validate('name', 'surname').then((result) => {
      if (result) {
        api.auth
          .registrationFirstStep({
            firstName: name.value,
            lastName: surname.value,
          })
          .then((regToken) => {
            registrationToken.value = regToken
            step.value = 2
            isCodeSent.value = false
          })
          .finally(() => {
            isLoading.value = false
          })
      } else {
        isLoading.value = false
      }
    })
  }
}

const finishStep2 = () => {
  if (isCodeSent.value) {
    if (!isLoading.value) {
      isLoading.value = true

      validator.validate('email', 'emailCode').then((result) => {
        if (result) {
          api.auth
            .registrationVerificationCode({
              email: email.value,
              code: emailCode.value.toUpperCase(),
              registrationToken: registrationToken.value,
            })
            .then(() => {
              // TODO: Temporary disable TP control
              // chooseTp.value = null
              step.value = 3
            })
            .finally(() => {
              isLoading.value = false
            })
        } else {
          isLoading.value = false
        }
      })
    }
  } else {
    sendEmailCode()
  }
}

const finishStep3 = () => {
  if (agreeTerms.value && chooseTp.value !== null) {
    if (password.value !== repeatPassword.value) {
      validator.setError('repeatPassword', 'пароли должны совпадать')
    } else {
      if (!isLoading.value) {
        isLoading.value = true

        validator.validate('password').then((result) => {
          if (result) {
            api.auth
              .registrationThirdStep({
                password: password.value,
                registrationToken: registrationToken.value,
              })
              .then(async ({ token }) => {
                await useAuthStore().setAccessToken(token)
                await router.push({ name: 'greetings' })
              })
              .finally(() => {
                isLoading.value = false
              })
          } else {
            isLoading.value = false
          }
        })
      }
    }
  }
}

const sendEmailCode = () => {
  if (!isLoading.value) {
    isLoading.value = true

    validator.validate('email').then((result) => {
      if (result) {
        api.auth
          .registrationSecondStep({
            email: email.value,
            registrationToken: registrationToken.value,
          })
          .then(() => {
            isCodeSent.value = true
          })
          .catch((error) => {
            if (error.response.status === 400 && error.response.data.message) {
              validator.setError(
                'email',
                error.response.data.message.toLowerCase(),
              )
            }
          })
          .finally(() => {
            isLoading.value = false
          })
      } else {
        isLoading.value = false
      }
    })
  }
}

const chooseTpIndex = (index: number) => {
  chooseTp.value = index
}

const termsOpen = () => {
  termsShow.value = true
}

const termsClose = (agree: boolean) => {
  agreeTerms.value = agree
}
</script>

<template>
  <main>
    <div
      v-if="step === 1"
      class="form-block"
    >
      <form ref="form1">
        <h2>Регистрация</h2>

        <img
          class="only-mobile"
          src="../assets/img/img-register.gif"
          alt="Register"
        />

        <FormText
          v-model="name"
          :ref="(el: typeof FormText) => validator.addItem('name', el)"
          id="name"
          :rules="Yup.string().required()"
          type="text"
          label="Имя:"
        />
        <FormText
          v-model="surname"
          :ref="(el: typeof FormText) => validator.addItem('surname', el)"
          id="surname"
          :rules="Yup.string().required()"
          type="text"
          label="Фамилия:"
        />
      </form>

      <div class="actions">
        <div
          class="btn primary"
          :class="{ loading: isLoading }"
          @click="finishStep1"
        >
          Продолжить
        </div>

        <div class="alt-block">
          <div class="alt-login">
            <a :href="googleLogin">
              <img
                src="../assets/img/log-google.png"
                alt="Google"
              />
            </a>

            <div>Продолжить с</div>

            <a :href="appleLogin">
              <img
                src="../assets/img/log-apple.png"
                alt="Apple"
              />
            </a>
          </div>

          <p>
            Уже есть аккаунт?
            <RouterLink :to="{ name: 'login' }">Войти</RouterLink>
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="step === 2"
      class="form-block"
    >
      <form ref="form2">
        <h2>Еще немного</h2>

        <FormText
          v-model="email"
          :ref="(el: typeof FormText) => validator.addItem('email', el)"
          id="email"
          :rules="Yup.string().email().required()"
          type="email"
          label="Ваша почта:"
          :disabled="isCodeSent"
        />
        <FormText
          v-if="isCodeSent"
          v-model="emailCode"
          :ref="(el: typeof FormText) => validator.addItem('emailCode', el)"
          id="emailCode"
          :rules="Yup.string().required()"
          type="code"
          label="Код верификации Email:"
        />
      </form>

      <div class="actions">
        <div
          v-if="isCodeSent"
          class="repeat-code"
        >
          <p>
            Не пришел код?
            <br class="only-desktop" />
            Запросить повторно
          </p>

          <div
            :class="{ disabled: isLoading }"
            @click="sendEmailCode"
          >
            <span class="icon-refresh"></span>
          </div>
        </div>

        <div
          class="btn primary"
          :class="{ loading: isLoading }"
          @click="finishStep2"
        >
          {{ isCodeSent ? 'Продолжить' : 'Выслать код' }}
        </div>
      </div>
    </div>

    <div
      v-if="step === 3"
      class="form-block"
    >
      <form ref="form3">
        <h2>Почти все!</h2>

        <FormText
          v-model="password"
          :ref="(el: typeof FormText) => validator.addItem('password', el)"
          id="password"
          :rules="Yup.string().required()"
          type="password"
          label="Придумайте пароль:"
        />
        <FormText
          v-model="repeatPassword"
          :ref="
            (el: typeof FormText) => validator.addItem('repeatPassword', el)
          "
          id="repeatPassword"
          type="password"
          label="Повторите пароль:"
        />
      </form>

      <div class="only-mobile">
        <BnrFree />
      </div>

      <div v-if="false && windowStore.isMobile">
        <p class="text-mini">
          Нам важна любая Поддержка!
          <span class="emotions-heart-hands"></span>
        </p>
        <Plans
          can-choose
          @choose="chooseTpIndex"
        />
      </div>

      <div class="actions">
        <div class="terms-box">
          <FormCheckbox
            v-model="agreeTerms"
            id="agreeTerms"
            @click.prevent="termsOpen"
          />

          <label
            for="agreeTerms"
            @click.prevent="termsOpen"
          >
            Прочитал(а) и принимаю
            <span class="link">Условия использования</span>
            сервиса Papaloves
          </label>
        </div>

        <div
          class="btn primary"
          :class="{
            disabled: !agreeTerms || chooseTp === null,
            loading: isLoading,
          }"
          @click="finishStep3"
        >
          Создать аккаунт!
        </div>
      </div>
    </div>

    <div class="img-block only-desktop">
      <img
        v-if="step != 3"
        src="../assets/img/img-register.gif"
        alt="Register"
      />

      <div class="only-desktop">
        <BnrFree mobile />
      </div>

      <div v-if="false && step === 3 && windowStore.isDesktop">
        <p class="text-mini">
          Нам важна любая Поддержка!
          <span class="emotions-heart-hands"></span>
        </p>
        <Plans
          can-choose
          @choose="chooseTpIndex"
        />
      </div>
    </div>
  </main>

  <Terms
    v-model="termsShow"
    @close="termsClose"
  />
</template>

<style scoped lang="scss">
main {
  padding-top: 25px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: calc(100dvh - 105px);
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    padding-top: 160px;
    align-items: center;
    min-height: 0;

    > div {
      flex: 0 0 560px;
    }
  }
}

.form-block {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  text-align: center;

  @media screen and (min-width: $desktop) {
    align-items: center;
  }

  h2 {
    text-align: center;

    @media screen and (min-width: $desktop) {
      margin-bottom: 40px;
    }
  }

  form {
    img {
      margin: 0 auto;
      display: block;
      width: 210px;
      height: 210px;
      max-width: 100%;
    }

    .field-wrapper {
      display: inline-block;
      width: 100%;

      @media screen and (min-width: $desktop) {
        width: 335px;
      }
    }
  }

  .actions {
    margin-top: 30px;

    @media screen and (min-width: $desktop) {
      margin-top: 60px;
    }
  }

  .btn {
    margin: 0 auto;
    display: block;
    width: 255px;
    max-width: 100%;
  }
}

.img-block {
  img {
    margin: 0 auto;
    display: block;
    width: 500px;
    height: 500px;
    max-width: 100%;
  }

  .text-mini {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 500;
  }
}

.alt-block {
  margin: 43px auto 0;
  width: 255px;
  max-width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 400;

  @media screen and (min-width: $desktop) {
    margin-top: 50px;
    width: 335px;
    font-size: 15px;
    font-weight: 500;
  }

  > p {
    color: rgba(34, 34, 34, 0.8);
  }
}

.alt-login {
  margin-bottom: 15px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  color: #b6b6b6;

  @media screen and (min-width: $desktop) {
    margin-bottom: 20px;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: #ffffff;
    box-shadow: 0 20px 40px 0 #cad9ef66;
    border-radius: 20px;

    img {
      display: block;
      width: 32px;
    }
  }
}

.repeat-code {
  margin: 0 10px 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  color: rgba(34, 34, 34, 0.8);

  @media screen and (min-width: $desktop) {
    width: 335px;
    font-size: 15px;
    font-weight: 500;
  }

  div {
    padding: 18px 0;
    width: 60px;
    text-align: center;
    font-size: 24px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0 20px 40px 0 #cad9ef66;
    box-sizing: border-box;
    cursor: pointer;

    span:before {
      color: #015bb0;
    }
  }
}

.text-mini {
  display: inline-flex;
  align-items: center;
}

.terms-box {
  margin-bottom: 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  color: rgba(34, 34, 34, 0.8);

  @media screen and (min-width: $desktop) {
    width: 335px;
    font-size: 15px;
    font-weight: 500;
  }

  label {
    margin-left: 10px;
  }
}

.emotions-heart-hands {
  margin-left: 12px;
  --size: 28;

  @media screen and (min-width: $desktop) {
    --size: 40;
  }
}
</style>
