<script setup lang="ts">
import { inject, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@src/store/auth.ts'
import { useAvatarStore } from '@src/store/avatar.ts'
import { useSearchStore } from '@src/store/search.ts'
import FormText from '@src/components/form/FormText.vue'
import { LocalEventBus } from '@src/event-bus.ts'

const router = useRouter()
const route = useRoute()
const authStore = useAuthStore()
const avatarStore = useAvatarStore()
const searchStore = useSearchStore()
const eventBus = inject(LocalEventBus)

const topBar = ref<string>('default')
const profileCode = ref(false)
const diaryEditor = ref(false)
const avatarData = ref<string | null>(null)
const search = ref('')
const searchResultsTotal = ref<number | null>(null)

const showSearch = ref(false)
const menuShowed = ref(false)
const desktopShowFull = ref(false)

const openMenu = () => {
  eventBus?.emit('mainMenu', 'show')
}

const closeMenu = () => {
  eventBus?.emit('mainMenu', 'hide')
}

const closeProfile = () => {
  if (profileCode.value) {
    eventBus?.emit('profileCodeClose', 'close')
  } else {
    router.push({ name: 'home' })
  }
}

const closeRemove = () => {
  router.push({ name: 'home' })
}

authStore.$subscribe(
  (_, state) => {
    if (state.user && state.user.avatar) {
      avatarStore.setAvatar(state.user.avatar)
    } else {
      avatarStore.clearAvatar()
    }
  },
  {
    immediate: true,
  },
)

avatarStore.$subscribe(
  (_, state) => {
    avatarData.value = state.avatarData
  },
  {
    immediate: true,
  },
)

searchStore.$subscribe((_, state) => {
  searchResultsTotal.value = state.resultsTotal
})

watch(
  () => route.meta.topBar,
  (value) => {
    topBar.value = value ?? 'default'
  },
  {
    immediate: true,
  },
)

eventBus?.on('profileCode', (action) => {
  profileCode.value = action === 'show'
})

eventBus?.on('editor', (action) => {
  if (['show', 'close'].includes(action)) {
    diaryEditor.value = action === 'show'
  }
})

eventBus?.on('clearSearch', () => {
  search.value = ''
  searchResultsTotal.value = null
  desktopShowFull.value = false
})

watch(
  () => search.value,
  (query) => {
    searchStore.query = query

    if (query.length > 0) {
      searchStore.runSearch = true

      if (route.name !== 'search') {
        router.push({ name: 'search' })
      }
    }
  },
  {
    immediate: true,
  },
)

onMounted(() => {
  eventBus?.on('topSearch', (action) => {
    showSearch.value = action === 'show'
  })

  eventBus?.on('mainMenu', (action) => {
    menuShowed.value = action === 'show'
  })
})

const goToSearch = () => {
  eventBus?.emit('mainMenu', 'hide')
  router.push({ name: 'search' })
}

const desktopTextBlur = () => {
  desktopShowFull.value = search.value !== ''
}

const desktopTextFocus = () => {
  desktopShowFull.value = true
}

const diaryEditorUpload = () => {
  eventBus?.emit('editor', 'upload')
}

const diaryEditorTrash = () => {
  eventBus?.emit('editor', 'trash')
}
</script>

<template>
  <div class="top">
    <div class="top--left">
      <RouterLink
        class="icon-link"
        :to="{ name: authStore.isAuth ? 'profile' : 'login' }"
      >
        <template v-if="authStore.isAuth">
          <img
            v-if="avatarData"
            :src="avatarData"
            alt="avatar"
          />
          <div
            v-else
            class="icon-avatar"
          ></div>
        </template>
        <div
          v-else
          class="icon-user"
        ></div>
      </RouterLink>

      <RouterLink
        v-if="topBar === 'profile' && !profileCode"
        class="icon-link"
        :to="{ name: 'remove' }"
      >
        <div class="icon-trash"></div>
      </RouterLink>
    </div>

    <RouterLink
      class="top--logo"
      :class="{ hidden: ['profile', 'remove'].includes(topBar) || diaryEditor }"
      :to="{ name: authStore.isAuth ? 'home' : 'index' }"
    >
      <img
        src="../assets/img/logo.png"
        alt="Logo"
      />
    </RouterLink>

    <div class="top--right">
      <template v-if="diaryEditor">
        <div
          class="menu-btn icon-link"
          @click="diaryEditorTrash"
        >
          <div class="icon-trash"></div>
        </div>

        <div
          class="menu-btn icon-link"
          @click="diaryEditorUpload"
        >
          <div class="icon-camera"></div>
        </div>
      </template>
      <template v-else-if="topBar === 'profile'">
        <div
          class="menu-btn icon-link"
          @click="closeProfile"
        >
          <div class="icon-close"></div>
        </div>
      </template>
      <template v-else-if="topBar === 'remove'">
        <div
          class="menu-btn icon-link"
          @click="closeRemove"
        >
          <div class="icon-close"></div>
        </div>
      </template>
      <template v-else>
        <div
          v-if="menuShowed"
          class="menu-btn icon-link"
          @click="closeMenu"
        >
          <div class="icon-close"></div>
        </div>
        <div
          v-else
          class="menu-btn icon-link"
          @click="openMenu"
        >
          <div class="icon-menu"></div>
        </div>

        <div
          v-if="showSearch"
          class="only-mobile icon-link"
          @click="goToSearch"
        >
          <div class="icon-search"></div>
        </div>

        <div
          v-if="!authStore.isAuth"
          class="top-search-field only-desktop"
          :class="{ full: desktopShowFull }"
        >
          <FormText
            v-model="search"
            id="search"
            type="search"
            placeholder="поиск дневника"
            @blur="desktopTextBlur"
            @focus="desktopTextFocus"
          />

          <div class="search-results">
            <template v-if="search.length < 3">
              <span class="icon-search"></span>
              <span>введите 3 символа</span>
            </template>
            <template v-else-if="searchResultsTotal === 0">
              <span class="icon-error"></span>
              <span>к сожалению, ничего не найдено<br />повторите поиск</span>
            </template>
            <template v-else-if="searchResultsTotal !== null">
              <span class="icon-like"></span>
              <span>
                найдено {{ searchResultsTotal }}
                {{
                  $pluralize(searchResultsTotal, [
                    'дневник',
                    'дневника',
                    'дневников',
                  ])
                }}
              </span>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top {
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px 20px 10px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: url('/images/bg-mobile.jpg') top center no-repeat;
  background-size: cover;
  z-index: 99;
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    position: static;
    padding: 0;
    background: none;
  }

  &--left,
  &--right {
    flex: 1 1 50%;
    display: flex;

    .icon-link {
      display: block;
      width: 24px;
      height: 24px;
      cursor: pointer;

      @media screen and (min-width: $desktop) {
        width: 40px;
        height: 40px;
      }

      div {
        font-size: 24px;

        @media screen and (min-width: $desktop) {
          font-size: 40px;
        }
      }

      img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        border-radius: 50%;
      }
    }
  }

  &--left {
    flex-flow: row nowrap;
    align-items: center;

    > * {
      margin-right: 20px;
    }
  }

  &--right {
    flex-flow: row-reverse nowrap;
    align-items: center;

    > * {
      margin-left: 20px;
    }

    .top-search-field {
      position: relative;

      .field-wrapper {
        width: 200px;
      }

      .search-results {
        display: none;
      }

      &.full {
        .field-wrapper {
          width: 335px;
        }

        .search-results {
          position: absolute;
          top: 60px;
          left: 0;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 9px;
          font-size: 17px;
          font-weight: 500;

          [class^='icon-'] {
            font-size: 24px;
          }
        }
      }
    }
  }

  &--logo {
    display: block;
    height: 40px;

    &.hidden {
      visibility: hidden;
    }

    img {
      height: 40px;
    }

    @media screen and (min-width: $desktop) {
      height: 60px;

      img {
        height: 60px;
      }
    }
  }
}
</style>
