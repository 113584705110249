<script setup lang="ts">
import { inject, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { api } from '@src/api'
import Validator, { Yup } from '@src/validator.ts'
import { useAvatarStore } from '@src/store/avatar.ts'
import { LocalEventBus } from '@src/event-bus.ts'
import Diary from '@src/components/Diary.vue'
import FormText from '@src/components/form/FormText.vue'
import FormArea from '@src/components/form/FormArea.vue'
import RemoveDiary from '@src/components/RemoveDiary.vue'
import loadSecImage from '@src/loadSecImage.ts'
import logoIconAvatar from '@src/assets/img/logo-icon.png'
import type { DiaryType, DiaryTypeFile } from '@src/api/diaries.ts'

const uploadTypes = {
  images: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
  videos: ['video/mp4', 'video/quicktime', 'video/x-quicktime', 'video/avi'],
}

const avatarStore = useAvatarStore()
const eventBus = inject(LocalEventBus)

const diariesEl = ref<HTMLElement | null>(null)
const uploadEl = ref<HTMLInputElement | null>(null)
const showEditor = ref(false)
const startDiary = ref<DiaryType>({
  id: 0,
  type: 'start',
  theme: '',
  text: '',
  reaction: null,
  userId: 0,
  files: [],
})
const diaries = ref<DiaryType[]>([])
const diariesDraftCount = ref(0)
const diariesNonDraftCount = ref(0)
const validator = new Validator()
const diaryId = ref(0)
const diaryTheme = ref('')
const diaryText = ref('')
const diaryDraft = ref(false)
const diaryFiles = ref<DiaryTypeFile[]>([])
const isLoadingDiaries = ref(false)
const isLoading = ref(false)
const deleteDiaryId = ref(0)
const showDeletePopup = ref(false)

const currentPage = ref(1)
const totalPages = ref(1)

const availableUploadTypes = () =>
  [...uploadTypes.images, ...uploadTypes.videos].join(',')

const loadDiaries = (page = 1) => {
  isLoadingDiaries.value = true
  currentPage.value = page

  if (page === 1) {
    diaries.value = []
  }

  api.diaries
    .list(page)
    .then((data) => {
      totalPages.value = Math.ceil(data.total / 10)
      diariesDraftCount.value = data.draftCount ?? 0
      diariesNonDraftCount.value = data.nonDraftCount ?? 0

      data.diaries.forEach((item) => {
        item.type = item.isDraft ? 'draft' : ''
        diaries.value.push(item)
      })

      showEditor.value = false
      eventBus?.emit('editor', 'close')

      if (page === 1) {
        nextTick(() => {
          diariesEl.value?.scrollTo(0, 0)
        })
      }
    })
    .finally(() => {
      isLoadingDiaries.value = false
    })
}

const newDiary = async () => {
  diaryId.value = 0
  diaryTheme.value = ''
  diaryText.value = ''
  diaryDraft.value = true
  diaryFiles.value = []

  await nextTick()
  showEditor.value = true
  eventBus?.emit('editor', 'show')
}

const editDiary = async (id: number) => {
  diaryId.value = id
  diaryTheme.value = ''
  diaryText.value = ''
  diaryDraft.value = true
  diaryFiles.value = []

  diaries.value.forEach((value) => {
    if (value.id === diaryId.value) {
      diaryId.value = value.id
      diaryTheme.value = value.theme
      diaryText.value = value.text
      diaryDraft.value = value.isDraft ?? false
      diaryFiles.value = value.files ?? []
    }
  })

  await nextTick()
  showEditor.value = true
  eventBus?.emit('editor', 'show')
}

const handleClickRemove = async (id: number) => {
  if (id === 0) {
    showEditor.value = false
    eventBus?.emit('editor', 'close')
  } else {
    deleteDiaryId.value = id
    showDeletePopup.value = true
  }
}

const handlePopupDelete = () => {
  if (deleteDiaryId.value !== 0) {
    api.diaries.delete(deleteDiaryId.value).then(() => {
      loadDiaries()
    })
    deleteDiaryId.value = 0
    showDeletePopup.value = false
  }
}

const handleChange = () => {}

const handleSave = (isDraft: boolean) => {
  if (!isLoading.value) {
    isLoading.value = true

    validator.validateAll().then((result) => {
      if (result) {
        if (diaryId.value === 0) {
          api.diaries
            .create({
              theme: diaryTheme.value,
              text: diaryText.value,
              isDraft: isDraft,
              fileIds: diaryFiles.value.map((item) => item.uuid),
            })
            .then(() => {
              loadDiaries()
            })
            .finally(() => {
              isLoading.value = false
            })
        } else {
          api.diaries
            .update(diaryId.value, {
              theme: diaryTheme.value,
              text: diaryText.value,
              isDraft: isDraft,
              fileIds: diaryFiles.value.map((item) => item.uuid),
            })
            .then((data) => {
              if (diaryDraft.value !== isDraft) {
                loadDiaries()
              } else {
                diaries.value.forEach((value, index) => {
                  if (value.id === diaryId.value) {
                    showEditor.value = false
                    eventBus?.emit('editor', 'close')

                    nextTick(() => {
                      diaries.value[index] = data
                      diaries.value[index].type = data.isDraft ? 'draft' : ''
                    })
                  }
                })
              }
            })
            .finally(() => {
              isLoading.value = false
            })
        }
      } else {
        isLoading.value = false
      }
    })
  }
}

const uploadFile = async (event: Event) => {
  const target = event.target as HTMLInputElement

  if (target.files) {
    eventBus?.emit('loading', 'show')

    for (const file of target.files) {
      try {
        if (uploadTypes.images.includes(file.type)) {
          const { data } = await api.files.uploadImage('DIARY', file)

          diaryFiles.value.push({
            fileType: 'image',
            uuid: data,
          })
        }

        if (uploadTypes.videos.includes(file.type)) {
          const { data } = await api.files.uploadVideo('DIARY', file)

          diaryFiles.value.push({
            fileType: 'video',
            uuid: data,
          })
        }
      } catch (err) {}
    }

    handleChange()
    eventBus?.emit('loading', 'hide')
  }
}

const removeFile = (index: number) => {
  diaryFiles.value.splice(index, 1)
}

eventBus?.on('editor', (action) => {
  switch (action) {
    case 'upload':
      uploadEl.value?.click()
      break
    case 'trash':
      handleClickRemove(diaryId.value)
      break
  }
})

watch(
  () => diaryTheme.value + diaryText.value,
  () => handleChange(),
)

onMounted(() => {
  loadDiaries()
  api.diaries.template().then(({ title, text }) => {
    startDiary.value.theme = title
    startDiary.value.text = text
  })

  diariesEl.value?.addEventListener('scroll', () => {
    if (
      !isLoadingDiaries.value &&
      currentPage.value < totalPages.value &&
      diariesEl.value &&
      diariesEl.value.scrollHeight - 200 <
        diariesEl.value.scrollTop + diariesEl.value.offsetHeight
    ) {
      loadDiaries(currentPage.value + 1)
    }
  })
})

onUnmounted(() => {
  eventBus?.emit('editor', 'close')
})
</script>

<template>
  <main v-show="!showEditor">
    <div class="header">
      <div class="header--count">
        <div class="emotions-ledger"></div>
        <div class="header--count--text">
          <span class="public">
            {{ diariesNonDraftCount }}
            {{
              $pluralize(diariesNonDraftCount, ['запись', 'записи', 'записей'])
            }}
          </span>
          <template v-if="diariesDraftCount > 0"
            >,<br />
            <span class="draft">
              {{ diariesDraftCount }}
              {{
                $pluralize(diariesDraftCount, [
                  'черновик',
                  'черновика',
                  'черновиков',
                ])
              }}
            </span>
          </template>
        </div>
      </div>
    </div>

    <div class="diaries--wrapper">
      <div
        ref="diariesEl"
        class="diaries"
      >
        <div
          v-if="startDiary.text === ''"
          class="diary"
        >
          <img
            src="../../assets/img/loading.svg"
            class="loading"
            alt="Loading"
          />
        </div>
        <Diary
          v-else
          :item="startDiary"
          :author-avatar-data="logoIconAvatar"
        />

        <Diary
          v-for="(diary, key) in diaries"
          :key="key"
          :item="diary"
          :author-avatar-data="avatarStore.avatarData"
          can-edit
          @edit="editDiary"
          @remove="handleClickRemove(diary.id)"
        />

        <img
          v-if="isLoadingDiaries"
          class="diaries-loading"
          src="../../assets/img/loading.svg"
          alt="Loading"
        />
      </div>
    </div>
  </main>

  <div
    v-show="!showEditor"
    class="icon-add"
    @click="newDiary"
  ></div>

  <div
    v-if="showEditor"
    class="editor"
  >
    <form ref="form">
      <FormText
        v-model="diaryTheme"
        :ref="(el: typeof FormText) => validator.addItem('theme', el)"
        id="theme"
        type="text"
        :rules="Yup.string().required()"
        placeholder="Тема"
      />
      <FormArea
        v-model="diaryText"
        :ref="(el: typeof FormArea) => validator.addItem('text', el)"
        id="text"
        :rules="Yup.string().required()"
      />

      <div class="editor--files">
        <input
          ref="uploadEl"
          type="file"
          multiple
          :accept="availableUploadTypes()"
          @change="uploadFile"
        />

        <div
          v-if="diaryFiles && diaryFiles.length > 0"
          class="editor--files--list"
        >
          <div
            v-for="(file, index) in diaryFiles"
            :key="file.uuid"
            class="editor--files--item"
          >
            <img
              :src="loadSecImage(file.uuid, 'thumbnail')"
              alt=""
            />
            <div
              class="icon-trash"
              @click="removeFile(index)"
            ></div>
          </div>
        </div>

        <div
          v-else
          class="editor--files--text"
        >
          <span class="emotions-camera-with-flash"></span>
          И, конечно же, старайтесь добавлять фото. Все получится!
          <span class="emotions-fire"></span>
        </div>
      </div>
    </form>

    <div class="actions">
      <div
        v-if="diaryDraft"
        class="btn"
        :class="{ loading: isLoading }"
        @click="handleSave(true)"
      >
        В черновики
      </div>

      <div
        class="btn primary"
        :class="{ loading: isLoading }"
        @click="handleSave(false)"
      >
        Сохранить
      </div>
    </div>
  </div>

  <RemoveDiary
    v-model="showDeletePopup"
    @delete="handlePopupDelete"
    @close="showDeletePopup = false"
  />
</template>

<style scoped lang="scss">
main {
  padding-top: 30px;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &--count {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 12px;

    &--text {
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      opacity: 0.8;

      @media screen and (min-width: $desktop) {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.1;
      }

      .draft {
        color: #015bb0;
      }
    }
  }
}

.emotions-ledger {
  --size: 26;

  @media screen and (min-width: $desktop) {
    --size: 32;
  }
}

.diaries {
  margin: 10px -15px 0;
  padding: 10px 15px 62px;
  height: calc(100dvh - 141px);
  box-sizing: border-box;
  overflow-y: scroll;
  scrollbar-width: thin;

  @media screen and (min-width: $desktop) {
    margin-top: 20px;
    height: calc(100dvh - 173px);
  }
}

.diaries-loading {
  margin: 20px auto 0;
  display: block;
  width: 50px;

  @media screen and (min-width: $desktop) {
    margin-top: 60px;
    width: 80px;
  }
}

.icon-add {
  position: absolute;
  right: 20px;
  bottom: 30px;
  padding: 18px;
  font-size: 24px;
  color: #0160b4;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 20px 40px 0 #cad9efcc;
  z-index: 9;
  cursor: pointer;

  @media screen and (min-width: $desktop) {
    left: calc(50% + 610px);
    right: auto;
    bottom: 60px;
  }
}

.editor {
  padding-top: 40px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  min-height: calc(100dvh - 120px);
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    padding-top: 160px;
    align-items: center;
    min-height: 0;
  }

  :deep(.field-wrapper) {
    @media screen and (min-width: $desktop) {
      width: 1000px;
    }
  }

  :deep(textarea) {
    min-height: 260px;
    max-height: 260px;
  }

  &--files {
    margin: 30px -20px 0;
    padding: 0 20px 10px;
    max-width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    overflow-x: auto;

    @media screen and (min-width: $desktop) {
      margin: 60px 0 0;
      padding: 0 0 10px;
      width: 1000px;
      font-size: 24px;
      line-height: 1.4;
    }

    &--list {
      display: flex;
      flex-flow: row nowrap;
    }

    &--item {
      flex: 0 0 90px;
      position: relative;
      padding-right: 20px;
      box-shadow: 0 7.43px 21.23px 0 #00000008;

      @media screen and (min-width: $desktop) {
        flex-basis: 150px;
      }

      img {
        width: 90px;
        height: 90px;
        object-fit: cover;
        border-radius: 20px;

        @media screen and (min-width: $desktop) {
          width: 150px;
          height: 150px;
          border-radius: 40px;
        }
      }

      .icon-trash {
        position: absolute;
        top: 5px;
        right: 25px;
        padding: 2px 4px;
        font-size: 16px;
        color: #222222;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 50%;
        cursor: pointer;

        @media screen and (min-width: $desktop) {
          top: 10px;
          right: 30px;
          padding: 0 6px;
          font-size: 32px;
        }
      }
    }

    &--text {
      @media screen and (min-width: $desktop) {
        margin: 0 auto;
        width: 570px;
        text-align: center;
      }
    }

    input {
      display: none;
    }

    span {
      --size: 18;

      @media screen and (min-width: $desktop) {
        --size: 32;
      }
    }
  }
}

.actions {
  display: flex;
  flex-flow: row nowrap;
  gap: 20px;

  @media screen and (min-width: $desktop) {
    margin: 50px auto 0;
    width: 335px;
  }
}
</style>
