<script setup lang="ts">
import { nextTick, onMounted, ref } from 'vue'
import { api } from '@src/api'
import { useAuthStore } from '@src/store/auth.ts'
import Diary from '@src/components/Diary.vue'
import loadSecImage from '@src/loadSecImage.ts'
import { DiaryType } from '@src/api/diaries.ts'

const authStore = useAuthStore()
const diariesEl = ref<HTMLElement | null>(null)
const diaries = ref<DiaryType[]>([])
const diariesNonDraftCount = ref(0)
const parentAvatar = ref('')
const isLoadingDiaries = ref(false)

const currentPage = ref(1)
const totalPages = ref(1)

const loadDiaries = (page = 1) => {
  isLoadingDiaries.value = true
  currentPage.value = page

  if (page === 1) {
    diaries.value = []
  }

  api.diaries
    .list(page)
    .then((data) => {
      totalPages.value = Math.ceil(data.total / 10)
      diariesNonDraftCount.value = data.total

      data.diaries.forEach((item) => {
        item.type = item.isDraft ? 'draft' : ''
        diaries.value.push(item)
      })

      if (page === 1) {
        nextTick(() => {
          diariesEl.value?.scrollTo(0, 0)
        })
      }
    })
    .finally(() => {
      isLoadingDiaries.value = false
    })
}

onMounted(() => {
  loadDiaries()
  parentAvatar.value = ''

  if (authStore.parent && authStore.parent.avatar !== null) {
    parentAvatar.value = loadSecImage(authStore.parent.avatar, 'thumbnail')
  }

  diariesEl.value?.addEventListener('scroll', () => {
    if (
      !isLoadingDiaries.value &&
      currentPage.value < totalPages.value &&
      diariesEl.value &&
      diariesEl.value.scrollHeight - 200 <
        diariesEl.value.scrollTop + diariesEl.value.offsetHeight
    ) {
      loadDiaries(currentPage.value + 1)
    }
  })
})
</script>

<template>
  <main>
    <div class="header">
      <div class="header--count">
        <div class="emotions-ledger"></div>
        <div class="header--count--text">
          <span class="public">
            {{ diariesNonDraftCount }}
            {{
              $pluralize(diariesNonDraftCount, ['запись', 'записи', 'записей'])
            }}
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="isLoadingDiaries || diaries.length > 0"
      class="diaries--wrapper"
    >
      <div
        ref="diariesEl"
        class="diaries"
      >
        <Diary
          v-for="(diary, key) in diaries"
          :key="key"
          :item="diary"
          :author-avatar-data="parentAvatar"
          can-reaction
        />

        <img
          v-if="isLoadingDiaries"
          class="diaries-loading"
          src="../../assets/img/loading.svg"
          alt="Loading"
        />
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss">
main {
  padding-top: 30px;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &--count {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 12px;

    &--text {
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      opacity: 0.8;

      @media screen and (min-width: $desktop) {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.1;
      }
    }
  }
}

.emotions-ledger {
  --size: 26;

  @media screen and (min-width: $desktop) {
    --size: 32;
  }
}

.diaries {
  margin: 10px -15px 0;
  padding: 10px 15px 62px;
  height: calc(100dvh - 141px);
  box-sizing: border-box;
  overflow-y: scroll;
  scrollbar-width: thin;

  @media screen and (min-width: $desktop) {
    margin-top: 20px;
    height: calc(100dvh - 173px);
  }
}

.diaries-loading {
  margin: 20px auto 0;
  display: block;
  width: 50px;

  @media screen and (min-width: $desktop) {
    margin-top: 60px;
    width: 80px;
  }
}
</style>
