<script setup lang="ts">
import { inject, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { LocalEventBus } from '@src/event-bus.ts'

const route = useRoute()
const router = useRouter()
const eventBus = inject(LocalEventBus)

const topBar = ref<string>('default')
const menuShowed = ref(false)

const openMenu = () => {
  eventBus?.emit('mainMenu', 'show')
}

const closeMenu = () => {
  eventBus?.emit('mainMenu', 'hide')
}

const closeProfile = () => {
  router.push({ name: 'diary' })
}

watch(
  () => route.meta.topBar,
  (value) => {
    topBar.value = value ?? 'default'
  },
  {
    immediate: true,
  },
)

onMounted(() => {
  eventBus?.on('mainMenu', (action) => {
    menuShowed.value = action === 'show'
  })
})
</script>

<template>
  <div class="top">
    <div class="top--left">
      <RouterLink
        class="icon-link avatar"
        :to="{ name: 'owner' }"
      >
        <img
          src="../../assets/img/child-avatar.png"
          alt="avatar"
        />
      </RouterLink>
    </div>

    <RouterLink
      class="top--logo"
      :to="{ name: 'diary' }"
    >
      <img
        src="../../assets/img/logo.png"
        alt="Logo"
      />
    </RouterLink>

    <div class="top--right">
      <template v-if="topBar === 'profile'">
        <div
          class="menu-btn icon-link"
          @click="closeProfile"
        >
          <div class="icon-close"></div>
        </div>
      </template>
      <template v-else>
        <div
          v-if="menuShowed"
          class="menu-btn icon-link"
          @click="closeMenu"
        >
          <div class="icon-close"></div>
        </div>
        <div
          v-else
          class="menu-btn icon-link"
          @click="openMenu"
        >
          <div class="icon-menu"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top {
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px 20px 10px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: url('/images/bg-mobile.jpg') top center no-repeat;
  background-size: cover;
  z-index: 99;
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    position: static;
    padding: 0;
    background: none;
  }

  &--left,
  &--right {
    flex: 1 1 50%;
    display: flex;

    .icon-link {
      display: block;
      width: 24px;
      height: 24px;
      cursor: pointer;

      @media screen and (min-width: $desktop) {
        width: 40px;
        height: 40px;
      }

      &.avatar {
        width: 40px;
        height: 40px;

        @media screen and (min-width: $desktop) {
          width: 60px;
          height: 60px;
        }
      }

      div {
        font-size: 24px;

        @media screen and (min-width: $desktop) {
          font-size: 40px;
        }
      }

      img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        border-radius: 50%;
      }
    }
  }

  &--left {
    flex-flow: row nowrap;
    align-items: center;

    > * {
      margin-right: 20px;
    }
  }

  &--right {
    flex-flow: row-reverse nowrap;
    align-items: center;

    > * {
      margin-left: 20px;
    }

    .top-search-field {
      position: relative;

      .field-wrapper {
        width: 200px;
      }

      .search-results {
        display: none;
      }

      &.full {
        .field-wrapper {
          width: 335px;
        }

        .search-results {
          position: absolute;
          top: 60px;
          left: 0;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 9px;
          font-size: 17px;
          font-weight: 500;

          [class^='icon-'] {
            font-size: 24px;
          }
        }
      }
    }
  }

  &--logo {
    display: block;
    height: 40px;

    &.hidden {
      visibility: hidden;
    }

    img {
      height: 40px;
    }

    @media screen and (min-width: $desktop) {
      height: 60px;

      img {
        height: 60px;
      }
    }
  }
}
</style>
