<script setup lang="ts">
import { watch } from 'vue'

const model = defineModel()
const emit = defineEmits<{
  afterOpen: []
  afterClose: []
}>()

const closePopup = () => {
  model.value = false
}

watch(model, () => (model ? emit('afterOpen') : emit('afterClose')))
</script>

<template>
  <div
    v-if="model"
    class="popup-wrapper"
  >
    <div class="popup">
      <div
        class="icon-close popup--close"
        @click="closePopup"
      ></div>

      <img
        class="popup--icon"
        src="../assets/img/img-info.gif"
        alt="Information"
      />

      <div class="popup--content">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.popup-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 101;
}

.popup {
  position: relative;
  margin: 0 15px;
  padding: 20px 20px 30px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 345px;
  background: #ffffff;
  border-radius: 30px;
  box-shadow: 0 20px 40px 0 #cad9ef66;
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    padding: 105px 70px 60px;
    flex-flow: row-reverse nowrap;
    width: 960px;
  }

  &--close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;

    @media screen and (min-width: $desktop) {
      top: 20px;
      right: 20px;
    }
  }

  &--icon {
    display: block;
    width: 160px;
    height: 160px;

    @media screen and (min-width: $desktop) {
      width: 460px;
      height: 460px;
    }
  }

  &--content {
    width: 100%;
    text-align: left;
  }
}
</style>
