<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import loadSecImage from '@src/loadSecImage.ts'
import VideoPlayer from '@src/components/VideoPlayer.vue'
import type { DiaryTypeFile } from '@src/api/diaries.ts'
import 'vue3-carousel/dist/carousel.css'

type SlideItem = DiaryTypeFile & {
  src: string
}

const props = defineProps<{
  files: DiaryTypeFile[]
  index: number
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const videoPlayer = ref<typeof VideoPlayer[]>()
const slides = ref<SlideItem[]>([])

const handleSlide = () => {
  videoPlayer.value?.forEach((player) => {
    player.videoPause()
  })
}

const handleClose = () => {
  emit('close')
}

onMounted(() => {
  slides.value = [
    ...props.files.slice(props.index),
    ...props.files.slice(0, props.index),
  ].map((item) => ({
    ...item,
    src: loadSecImage(item.uuid, 'full'),
  }))
})
</script>

<template>
  <div class="backdrop">
    <div class="slideshow">
      <div
        class="slideshow--close icon-close"
        @click="handleClose"
      ></div>

      <Carousel
        class="slideshow--images"
        :wrap-around="true"
        @slide-start="handleSlide"
      >
        <Slide
          v-for="slide in slides"
          :key="slide.uuid"
        >
          <img
            v-if="slide.fileType === 'image'"
            :src="slide.src"
            alt=""
          />
          <template v-else-if="slide.fileType === 'video'">
            <VideoPlayer
              ref="videoPlayer"
              :src="slide.src"
            />
          </template>
        </Slide>

        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<style lang="scss">
.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: stretch;
  background: rgba(235, 251, 255, 0.95);
  z-index: 105;
}

.slideshow {
  position: relative;
  margin: 30px 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    margin: 30px auto;
    width: 1160px;
  }

  &--close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 40px;
    cursor: pointer;
    z-index: 2;
  }

  &--images {
    padding: 45px 0;
    width: 100%;
    max-width: calc(100dvw - 40px);
    box-sizing: border-box;

    &.is-dragging {
      .carousel__slide {
        pointer-events: none;
      }
    }

    .carousel__slide {
      max-width: calc(100dvw - 40px);

      img,
      video {
        max-width: calc(100dvw - 40px);
        max-height: calc(100dvh - 150px);

        @media screen and (min-width: $desktop) {
          max-width: 1160px;
        }
      }

      img {
        border-radius: 30px;
      }
    }
  }

  .carousel__prev,
  .carousel__next {
    color: #ffffff;
    background: rgba(0, 0, 0, .7);
    border-radius: 50%;
  }

  .carousel__prev {
    left: 5px;
  }

  .carousel__next {
    right: 5px;
  }
}
</style>
