import { RouteRecordRaw } from 'vue-router'
import Home from '@src/pages/auth/Home.vue'
import Greetings from '@src/pages/auth/Greetings.vue'
import Profile from '@src/pages/auth/Profile.vue'
import Child from '@src/pages/auth/Child.vue'
import Remove from '@src/pages/auth/Remove.vue'

const authRoutes: RouteRecordRaw[] = [
  {
    path: 'greetings',
    component: Greetings,
    name: 'greetings',
  },
  {
    path: 'home',
    component: Home,
    name: 'home',
  },
  {
    path: 'profile',
    component: Profile,
    name: 'profile',
    meta: {
      topBar: 'profile',
    },
  },
  {
    path: 'remove',
    component: Remove,
    name: 'remove',
    meta: {
      topBar: 'remove',
    },
  },
  {
    path: 'child',
    component: Child,
    name: 'child',
  },
]

export default authRoutes
