import { api, request } from '@src/axios.ts'

export default class FilesResources {
  private resourceUrl = '/files'

  carouselFiles() {
    return request<string[]>({
      method: 'get',
      url: this.resourceUrl + '/carousel-files',
    })
  }

  // noinspection JSUnusedGlobalSymbols
  get(uuid: string, type: 'full' | 'thumbnail') {
    return request<Blob>({
      method: 'get',
      url: `${this.resourceUrl}/${uuid}`,
      params: {
        type,
      },
      responseType: 'blob',
    })
  }

  uploadImage(
    destination: 'AVATAR' | 'DIARY',
    file: File,
  ): Promise<{
    message: string
    data: string
  }> {
    let formData = new FormData()
    formData.append('file', file)

    return api.post(`${this.resourceUrl}/image/${destination}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  uploadVideo(
    destination: 'AVATAR' | 'DIARY',
    file: File,
  ): Promise<{
    message: string
    data: string
  }> {
    let formData = new FormData()
    formData.append('file', file)

    return api.post(`${this.resourceUrl}/video/${destination}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}
