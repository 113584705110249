import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import Vue3Toastify, { toast, type ToastContainerOptions } from 'vue3-toastify'
import pluralize from '@src/pluralize.ts'
import './style.scss'
import 'vue3-toastify/dist/index.css'
import pkg from '../package.json'

const app = createApp(App)
const pinia = createPinia()

app.directive<HTMLElement, boolean>('loading', (el, binding) => {
  if (binding.value) {
    el.classList.add('loading')
    el.setAttribute('disabled', 'disabled')
  } else {
    el.classList.remove('loading')
    el.removeAttribute('disabled')
  }
})

app.use(router)
app.use(pinia)
app.use(Vue3Toastify, {
  autoClose: 3000,
  position: toast.POSITION.TOP_CENTER,
  transition: toast.TRANSITIONS.SLIDE,
  limit: 3,
  type: toast.TYPE.INFO,
  hideProgressBar: true,
  clearOnUrlChange: false,
} as ToastContainerOptions)
app.use(pluralize)

Sentry.init({
  app,
  enabled: import.meta.env.VITE_SENTRY_DSN !== undefined,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: process.env.NODE_ENV,
  release: pkg.version,
  // Tracing
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['https://papaloves.catscode.ru'],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

app.mount('#app')

declare module '@vue/runtime-core' {
  // noinspection JSUnusedGlobalSymbols
  interface ComponentCustomProperties {
    $pluralize: Function
  }
}
