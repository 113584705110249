<script setup lang="ts">
import { onBeforeMount, onMounted, onUnmounted, provide, ref } from 'vue'
import mitt from 'mitt'
import { useRouter } from 'vue-router'
import { LocalEventBus, LocalEvents } from '@src/event-bus.ts'

const router = useRouter()
const localBus = mitt<LocalEvents>()
const loaded = ref(false)

provide(LocalEventBus, localBus)

onBeforeMount(async () => {
  await router.isReady()
  loaded.value = true
})

onMounted(() => {
  document.body.addEventListener('click', (evt) => {
    if ((evt.target as Node).parentElement?.classList.contains('menu-btn')) {
      return
    }

    const mainMenu = document.querySelector('.main-menu')

    if (mainMenu && !mainMenu.contains(evt.target as Node)) {
      localBus?.emit('mainMenu', 'hide')
    }
  })
})

onUnmounted(() => {
  localBus.all.clear()
})
</script>

<template>
  <router-view v-if="loaded" />

  <img
    v-else
    src="./assets/img/loading.svg"
    style="margin:50px auto;display:block;width:90px;"
    alt="Loading"
  />
</template>
