<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import { api } from '@src/api'
import loadSecImage from '@src/loadSecImage.ts'
import 'vue3-carousel/dist/carousel.css'

type SlideItem = {
  src: string
}

const slides = ref<SlideItem[]>([])

onMounted(() => {
  slides.value = []

  api.files.carouselFiles().then((items) => {
    slides.value = items.map((item) => ({
      src: loadSecImage(item, 'full'),
    }))
  })
})
</script>

<template>
  <Carousel :wrap-around="true">
    <Slide
      v-for="(slide, index) in slides"
      :key="index"
    >
      <img
        :src="slide.src"
        alt=""
      />
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<style lang="scss" scoped>
.carousel {
  padding: 10px 0;
  text-align: center;

  img {
    width: 190px;
    height: 190px;
    border-radius: 20px;

    @media screen and (min-width: $desktop) {
      width: 380px;
      height: 380px;
      border-radius: 40px;
    }
  }
}
</style>
