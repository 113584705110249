<script setup lang="ts">
defineModel<boolean>({ required: true })
const emit = defineEmits<{
  (e: 'delete'): void
  (e: 'close'): void
}>()
</script>

<template>
  <div
    v-if="modelValue"
    class="popup-wrapper"
  >
    <div class="popup">
      <div
        class="icon-close popup--close"
        @click="emit('close')"
      ></div>

      <div class="popup--content">
        <h3 class="h1 only-desktop">Удаление записи</h3>
        <h3 class="h2 only-mobile">Удаление записи</h3>

        <div class="content">
          Вы уверены, что хотите удалить эту запись?
        </div>

        <div class="buttons">
          <div
            class="btn primary"
            @click="emit('delete')"
          >
            Да
          </div>
          <div
            class="btn"
            @click="emit('close')"
          >
            Отмена
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.popup-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 101;
}

.popup {
  position: relative;
  margin: 0 15px;
  padding: 40px 20px 20px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background: #ffffff;
  border-radius: 30px;
  box-shadow: 0 20px 40px 0 #cad9ef66;
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    padding: 55px 40px 40px;
    width: 600px;
  }

  &--close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;

    @media screen and (min-width: $desktop) {
      top: 20px;
      right: 20px;
    }
  }

  &--content {
    width: 100%;
    text-align: center;
  }
}

.buttons {
  margin-top: 32px;
  display: flex;
  flex-flow: row nowrap;
  gap: 20px;

  @media screen and (min-width: $desktop) {
    margin-top: 48px;
  }
}
</style>