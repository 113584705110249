<template>
  <main>
    <div class="spacer-1"></div>
    <div class="greetings">
      <div class="greetings--content">
        <h2>Поздравляем!</h2>

        <div class="img-block only-mobile">
          <img
            class="house"
            src="../../assets/img/img-greetings.gif"
            alt="Greetings"
          />

          <img
            class="inner-logo"
            src="../../assets/img/img-greetings-logo.png"
            alt="Logo"
          />
        </div>

        <div class="text">
          <span class="emotions-confetti-ball"></span>
          Вы сделали правильный шаг
          <span class="emotions-confetti-ball"></span>
          <br />
          Пишите сколько угодно и когда угодно.<br />Желаем Вам удачи!
        </div>

        <RouterLink
          class="btn primary only-desktop"
          :to="{ name: 'home' }"
        >
          Создать первую запись
        </RouterLink>
      </div>

      <div class="img-block only-desktop">
        <img
          class="house"
          src="../../assets/img/img-greetings.gif"
          alt="Greetings"
        />

        <img
          class="inner-logo"
          src="../../assets/img/img-greetings-logo.png"
          alt="Logo"
        />
      </div>
    </div>
    <div class="spacer-2"></div>
    <div class="actions only-mobile">
      <RouterLink
        :to="{ name: 'home' }"
        class="btn primary"
      >
        Создать первую запись
      </RouterLink>
    </div>
  </main>
</template>

<style scoped lang="scss">
main {
  display: flex;
  flex-flow: column nowrap;
  min-height: calc(100dvh - 105px);

  @media screen and (min-width: $desktop) {
    display: block;
  }
}

.spacer-1 {
  flex-grow: 1;
}

.spacer-2 {
  flex-grow: 2;
}

.greetings {
  padding: 20px 20px 30px;
  background: #ffffff;
  border-radius: 30px;
  box-shadow: 0 20px 40px 0 #cad9ef66;
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    margin: 120px auto 0;
    padding: 45px 70px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 960px;
    box-sizing: border-box;
  }

  .img-block {
    position: relative;

    > img.house {
      @media screen and (max-width: $desktopM) {
        width: 200px;
        margin-top: 15px;
        margin-left: -72px;
        margin-bottom: 17px;
      }

      @media screen and (min-width: $desktop) {
        width: 350px;
        margin-left: 25px;
        margin-top: 40px;
        margin-bottom: 35px;
      }
    }

    > img.inner-logo {
      position: absolute;

      @media screen and (max-width: $desktopM) {
        top: 106px;
        left: 27px;
        width: 140px;
      }

      @media screen and (min-width: $desktop) {
        top: 200px;
        left: 200px;
      }
    }
  }

  &--content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    @media screen and (min-width: $desktop) {
      text-align: center;
    }

    h2 {
      margin-bottom: 5px;

      @media screen and (min-width: $desktop) {
        margin-bottom: 50px;
      }
    }

    .btn {
      margin-top: 50px;
    }
  }
}

.emotions-confetti-ball {
  margin-right: 5px;
  --size: 20;

  ~ .emotions-confetti-ball {
    margin-left: 5px;
  }
}
</style>
