<script setup lang="ts">
import { inject, nextTick, onMounted, onUpdated, ref } from 'vue'
import { format, isToday } from 'date-fns'
import { api } from '@src/api'
import { useAuthStore } from '@src/store/auth.ts'
import { LocalEventBus } from '@src/event-bus.ts'
import loadSecImage from '@src/loadSecImage.ts'
import type { DiaryType } from '@src/api/diaries.ts'

const props = defineProps<{
  item: DiaryType
  authorAvatarData: string | null
  canEdit?: boolean
  canReaction?: boolean
}>()

const emit = defineEmits<{
  edit: [number]
  remove: [number]
}>()

const types: Record<string, string> = {
  start: 'Начнем!',
  draft: 'черновик',
}

const emotions = [
  'emotions-red-heart',
  'emotions-heart-eyes',
  'emotions-loudly-crying-face',
  'emotions-screaming-face',
  'emotions-face-with-tears-of-joy',
  'emotions-broken-heart',
  'emotions-melting-face',
  'emotions-weary-face',
  'emotions-pleading-face',
  'emotions-star-struck',
  'emotions-sunglasses',
  'emotions-heart-hands',
  'emotions-raising-hands',
  'emotions-waving-hand',
  'emotions-folded-hands',
  'emotions-crying-cat',
  'emotions-angry-face',
  'emotions-neutral-face',
  'emotions-ok-hand',
  'emotions-fire',
  'emotions-birthday-cake',
  'emotions-direct-hit',
  'emotions-wrapped-gift',
  'emotions-balloon',
]

const authStore = useAuthStore()
const eventBus = inject(LocalEventBus)
const info = ref<HTMLElement | null>(null)
const title = ref<HTMLElement | null>(null)
const content = ref<HTMLElement | null>(null)
const showPlace = ref<HTMLElement | null>(null)
const showLink = ref(false)
const showPost = ref(false)
const showReactionBlock = ref(false)

const handleShow = () => {
  showPost.value = !showPost.value

  if (showPost.value) {
    content.value?.style.setProperty(
      'max-height',
      content.value?.scrollHeight + 'px',
    )
  } else {
    content.value?.style.removeProperty('max-height')
  }
}

const calculateHeight = () => {
  showLink.value = content.value !== null && content.value?.scrollHeight > 85
}

const outputDateTime = (dt: string) => {
  const date = new Date(dt)

  const output = [
    format(date, 'dd.MM.yyyy'),
    format(date, 'HH:mm'),
  ]

  if (isToday(date)) {
    output[0] = 'сегодня'
  }

  return output.join(', ')
}

const handleSlideshow = (index: number) => {
  eventBus?.emit('slideshow', {
    files: props.item.files ?? [],
    index,
  })
}

const handleShowReactionBlock = () => {
  if (props.canReaction) {
    if (props.item.reaction) {
      props.item.reaction = null
      api.diaries.setReaction(props.item.id, null)
    } else {
      showReactionBlock.value = true
    }
  }
}

const handleReaction = (emotion: string) => {
  showReactionBlock.value = false

  if (props.canReaction) {
    props.item.reaction = emotion
    api.diaries.setReaction(props.item.id, emotion)
  }
}

onMounted(() => {
  nextTick(() => {
    calculateHeight()
  })

  window.addEventListener('resize', () => {
    calculateHeight()
  })
})

onUpdated(() => {
  nextTick(() => {
    calculateHeight()
  })
})
</script>

<template>
  <div class="diary">
    <div class="diary--data">
      <div
        ref="info"
        class="diary--info"
      >
        <div class="diary--info--left">
          <img
            v-if="authorAvatarData"
            class="diary--author"
            :class="{ round: item.id !== 0 }"
            :src="authorAvatarData"
            alt="Author"
          />
          <div
            v-else
            class="icon-avatar diary--author--icon"
          ></div>

          <div class="diary--timestamp">
            <template v-if="authStore.user && item.type === 'start'">
              {{ outputDateTime(authStore.user.createdAt) }}
            </template>
            <template v-else-if="item.createdAt">
              {{ outputDateTime(item.createdAt) }}
            </template>
            <template v-else>сегодня</template>
          </div>
        </div>

        <div
          v-if="item.type && types[item.type]"
          class="diary--info--status"
          :class="item.type"
        >
          {{ types[item.type] }}
        </div>

        <div
          class="diary--info--right"
          :class="{ shift: item.reaction }"
        >
          <div
            v-if="canEdit"
            class="icon-edit"
            @click="emit('edit', item.id)"
          ></div>

          <div
            v-if="canEdit"
            class="icon-trash"
            @click="emit('remove', item.id)"
          ></div>
        </div>
      </div>

      <h4
        ref="title"
        class="diary--title"
      >
        {{ item.theme }}
      </h4>

      <div
        ref="content"
        class="diary--content animate"
      >
        <p v-for="line in item.text.split('\n')">{{ line }}</p>
      </div>

      <div
        ref="showPlace"
        class="diary--show-place"
      >
        <div
          v-if="showLink"
          class="diary--show"
          @click.stop="handleShow"
        >
          <p>{{ showPost ? 'скрыть' : 'читать больше' }}</p>
          <div
            v-show="!showPost"
            class="icon-chevron-down"
          ></div>
          <div
            v-show="showPost"
            class="icon-chevron-up"
          ></div>
        </div>
      </div>

      <div
        v-if="item.files && item.files.length > 0"
        class="diary--images"
      >
        <div
          v-for="(file, index) in item.files"
          :key="file.uuid"
        >
          <img
            :src="loadSecImage(file.uuid, 'thumbnail')"
            alt=""
            @click="handleSlideshow(index)"
          />
        </div>
      </div>
    </div>

    <div
      class="diary--reaction"
      :class="{ show: canReaction, active: item.reaction }"
      @click="handleShowReactionBlock"
    >
      <div :class="item.reaction ?? 'emotions-red-heart'"></div>
    </div>

    <div
      v-if="canReaction"
      class="diary--reaction-block"
      :class="{ show: showReactionBlock }"
    >
      <div
        v-for="emotion in emotions"
        :key="emotion"
        :class="emotion"
        @click="handleReaction(emotion)"
      ></div>
    </div>
  </div>
</template>
