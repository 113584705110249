<script setup lang="ts">
import { inject, ref, unref, watch } from 'vue'
import { toast } from 'vue3-toastify'
import { useAuthStore } from '@src/store/auth.ts'
import { api } from '@src/api'
import Validator, { Yup } from '@src/validator.ts'
import { LocalEventBus } from '@src/event-bus.ts'
import FormText from '@src/components/form/FormText.vue'
import loadSecImage from '@src/loadSecImage.ts'

// noinspection SpellCheckingInspection,LongLine
const avatarPixel =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
const passwordMask = '●●●●●●●●'

const authStore = useAuthStore()
const eventBus = inject(LocalEventBus)

const profileCode = ref(false)
const avatar = ref<HTMLInputElement | null>(null)
const avatarData = ref<string | null>(null)

const validator = new Validator()
const fullName = ref('')
const birthDate = ref<string | null>(null)
const login = ref('')
const password = ref(passwordMask)
const repeatPassword = ref('')
const passwordChanged = ref(false)
const canSaved = ref(false)
const newData = ref<{
  login?: string
  password?: string
}>({})
const emailCode = ref('')
const updateToken = ref('')
const isLoading = ref(false)

authStore.$subscribe(
  (_, state) => {
    if (state.user) {
      login.value = newData.value.login ?? state.user.login
      password.value = newData.value.password ?? passwordMask
      fullName.value = state.user.fullName
      birthDate.value = state.user.birthDate

      if (state.user.avatar) {
        avatarData.value = loadSecImage(state.user.avatar, 'full')
      } else {
        avatarData.value = null
      }
    }
  },
  {
    immediate: true,
  },
)

watch([fullName, birthDate, login, password], () => {
  if (authStore.user) {
    canSaved.value =
      fullName.value !== authStore.user.fullName ||
      birthDate.value !== authStore.user.birthDate ||
      login.value !== authStore.user.login ||
      (password.value !== '' && password.value !== passwordMask)

    passwordChanged.value =
      password.value !== '' && password.value !== passwordMask
  }
})

watch(passwordChanged, (newValue) => {
  if (newValue) {
    repeatPassword.value = ''
  }
})

eventBus?.on('profileCodeClose', () => {
  profileCode.value = false
  eventBus?.emit('profileCode', 'hide')
})

const handleUpload = () => {
  avatar.value?.click()
}

const uploadAvatar = (event: Event) => {
  eventBus?.emit('loading', 'show')
  const target = event.target as HTMLInputElement

  if (target.files) {
    api.files.uploadImage('AVATAR', target.files[0]).then(({ data }) => {
      api.users
        .profileUpdate({
          avatar: data,
        })
        .then((data) => {
          authStore.setUserData({
            ...data,
            fullName: data.fullName ?? '',
          })
        })
        .finally(() => {
          eventBus?.emit('loading', 'hide')
        })
    }).catch(() => {
      eventBus?.emit('loading', 'hide')
    })
  }
}

const handlePasswordFocus = () => {
  if (password.value === passwordMask) {
    password.value = ''
  }
}

const handlePasswordBlur = () => {
  if (password.value === '') {
    password.value = passwordMask
  }
}

const handleSave = () => {
  if (isLoading.value === false) {
    if (passwordChanged.value && password.value !== repeatPassword.value) {
      validator.setError('repeatPassword', 'пароли должны совпадать')
    } else {
      isLoading.value = true

      validator.validate('fullName', 'birthDate', 'login').then((result) => {
        if (result) {
          newData.value = {}

          if (login.value !== authStore.user?.login) {
            newData.value.login = login.value
          }

          if (password.value !== '' && password.value !== passwordMask) {
            newData.value.password = password.value
          }

          api.users
            .profileUpdate({
              fullName: fullName.value,
              birthDate: birthDate.value,
            })
            .then((data) => {
              if (Object.keys(newData.value).length === 0) {
                toast.success('Ваши изменения сохранены')
              }
              authStore.setUserData({
                ...data,
                fullName: data.fullName ?? '',
              })
              canSaved.value = false
            })
            .finally(() => {
              isLoading.value = false
            })

          if (Object.keys(newData.value).length > 0) {
            api.users.loginPassword(unref(newData)).then(({ token }) => {
              profileCode.value = true
              eventBus?.emit('profileCode', 'show')
              updateToken.value = token
            })
          }
        } else {
          isLoading.value = false
        }
      })
    }
  }
}

const handleCloseCheck = () => {
  newData.value = {}
  profileCode.value = false
  eventBus?.emit('profileCode', 'hide')
}

const handleRepeatCode = () => {
  isLoading.value = true

  api.users
    .loginPassword(unref(newData))
    .then(({ token }) => {
      updateToken.value = token
    })
    .finally(() => {
      isLoading.value = false
    })
}

const handleUpdateLoginPassword = () => {
  if (!isLoading.value) {
    isLoading.value = true

    validator.validate('emailCode').then((result) => {
      if (result) {
        api.users
          .checkUpdateLoginPassword({
            code: emailCode.value.toUpperCase(),
            token: updateToken.value,
          })
          .then((data) => {
            toast.success('Ваши изменения сохранены')
            newData.value = {}
            authStore.setUserData({
              ...data,
              fullName: data.fullName ?? '',
            })
            profileCode.value = false
            eventBus?.emit('profileCode', 'hide')
            passwordChanged.value = false
            canSaved.value = false
          })
          .finally(() => {
            isLoading.value = false
          })
      } else {
        isLoading.value = false
      }
    })
  }
}
</script>

<template>
  <div class="profile">
    <div
      class="profile--main"
      :class="{ hidden: profileCode }"
    >
      <div class="profile--avatar">
        <img
          :src="avatarData ?? avatarPixel"
          :class="{ uploaded: avatarData }"
          alt="avatar"
        />

        <div
          class="profile--upload"
          @click="handleUpload"
        >
          <input
            ref="avatar"
            type="file"
            accept="image/jpeg,image/png,image/gif"
            @change="uploadAvatar"
          />
          <div class="icon-camera"></div>
        </div>
      </div>

      <div class="profile--private">
        <div class="profile--name">
          {{ authStore.user?.fullName.split(' ')[0] }}
        </div>
        <div
          v-if="false"
          class="profile--subscribe"
        >
          <div class="profile--subscribe--data">
            <div class="profile--subscribe--name">поддержка Gold</div>
            <div class="profile--subscribe--period">
              <span>5 лет</span> (до 11.08.2029)
            </div>
          </div>
          <img
            src="/plans/plan4.png"
            alt="plan"
          />
        </div>
      </div>
    </div>

    <div
      class="profile--data"
      :class="{ hidden: profileCode }"
    >
      <form class="profile--form">
        <h2 class="only-desktop">Профиль</h2>

        <FormText
          v-model="fullName"
          :ref="(el: typeof FormText) => validator.addItem('fullName', el)"
          id="fullName"
          :rules="Yup.string().required()"
          type="text"
          label="ФИО:"
        />
        <FormText
          v-model="birthDate"
          :ref="(el: typeof FormText) => validator.addItem('birthDate', el)"
          id="birthDate"
          :rules="Yup.string().required()"
          type="date"
          label="Месяц и год рождения:"
        />
        <FormText
          v-model="login"
          :ref="(el: typeof FormText) => validator.addItem('login', el)"
          id="email"
          :rules="Yup.string().required()"
          type="email"
          label="Почта:"
        />
        <FormText
          v-model="password"
          :ref="(el: typeof FormText) => validator.addItem('password', el)"
          id="password"
          type="password"
          label="Пароль:"
          @focus="handlePasswordFocus"
          @blur="handlePasswordBlur"
        />
        <FormText
          v-if="passwordChanged"
          v-model="repeatPassword"
          :ref="
            (el: typeof FormText) => validator.addItem('repeatPassword', el)
          "
          id="repeatPassword"
          type="password"
          label="Повторите новый пароль:"
        />
      </form>

      <div class="actions">
        <div
          v-if="canSaved"
          class="btn primary"
          :class="{ loading: isLoading }"
          @click="handleSave"
        >
          Сохранить
        </div>
      </div>
    </div>

    <div
      class="profile--overlay only-desktop"
      :class="{ show: profileCode }"
    ></div>

    <div
      v-if="profileCode"
      class="check"
    >
      <div
        class="icon-close only-desktop"
        @click="handleCloseCheck"
      ></div>

      <form>
        <h2>Подтверждение</h2>

        <FormText
          v-model="emailCode"
          :ref="(el: typeof FormText) => validator.addItem('emailCode', el)"
          id="emailCode"
          :rules="Yup.string().required()"
          type="code"
          label="Код верификации Email:"
        />
      </form>

      <div class="actions">
        <div class="repeat-code">
          <p>
            Не пришел код?
            <br class="only-desktop" />
            Запросить повторно
          </p>

          <div
            :class="{ disabled: isLoading }"
            @click="handleRepeatCode"
          >
            <span class="icon-refresh"></span>
          </div>
        </div>

        <div
          class="btn primary"
          :class="{ loading: isLoading }"
          @click="handleUpdateLoginPassword"
        >
          Продолжить
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.profile {
  position: relative;
  padding-top: 35px;
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
  min-height: calc(100dvh - 105px);
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    padding-top: 160px;
    flex-flow: row-reverse nowrap;
    min-height: 0;

    > div {
      flex: 0 0 560px;
    }
  }

  &--main {
    position: relative;
    margin-left: 45px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 14px;

    &.hidden {
      @media screen and (max-width: $desktopM) {
        visibility: hidden;
      }
    }

    @media screen and (min-width: $desktop) {
      margin: 0;
      padding-top: 42px;
      flex-flow: column nowrap;
      gap: 37px;
    }
  }

  &--avatar {
    position: relative;
    width: 100px;
    height: 100px;

    @media screen and (min-width: $desktop) {
      width: 250px;
      height: 250px;
    }

    img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      background: url("/src/assets/img/avatar.gif") -130px -140px no-repeat;
      border-radius: 50%;
      overflow: hidden;

      @media screen and (max-width: $desktopM) {
        background-position-x: -50px;
        background-position-y: -57px;
        background-size: 200px;
      }

      &.uploaded {
        object-fit: cover;
        background: none;
      }
    }
  }

  &--upload {
    position: absolute;
    left: -22px;
    top: 30px;
    padding: 8px;
    width: 40px;
    height: 40px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 20px 40px 0 #cad9efcc;
    box-sizing: border-box;
    cursor: pointer;

    @media screen and (min-width: $desktop) {
      top: 85px;
      left: -44px;
      padding: 20px;
      width: 80px;
      height: 80px;
      font-size: 40px;
    }

    input {
      display: none;
    }
  }

  &--private {
    display: flex;
    flex-flow: column nowrap;
    gap: 15px;

    @media screen and (min-width: $desktop) {
      align-items: center;
      gap: 30px;
    }
  }

  &--name {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;

    @media screen and (min-width: $desktop) {
      font-size: 32px;
    }
  }

  &--subscribe {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    gap: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;

    @media screen and (min-width: $desktop) {
      gap: 30px;
      font-size: 15px;
      font-weight: 500;
    }

    img {
      height: 30px;

      @media screen and (min-width: $desktop) {
        height: 40px;
      }
    }

    span {
      color: #146fda;
    }

    &--data {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
    }
  }

  &--data {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    gap: 20px;

    &.hidden {
      @media screen and (max-width: $desktopM) {
        visibility: hidden;
      }
    }
  }

  &--form {
    @media screen and (min-width: $desktop) {
      width: 335px;
    }

    h2 {
      text-align: center;
    }

    .field-wrapper + .field-wrapper {
      @media screen and (min-width: $desktop) {
        margin-top: 30px;
      }
    }
  }

  &--overlay.show {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.45) 8.74%,
      rgba(255, 255, 255, 0.6) 100%
    );
  }
}

.check {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  @media screen and (min-width: $desktop) {
    top: 108px;
    bottom: auto;
    left: 50%;
    right: auto;
    padding: 124px 100px 60px;
    align-items: center;
    background: #ffffff;
    border-radius: 30px;
    box-shadow: 0 20px 40px 0 #cad9ef66;
    transform: translateX(-50%);
  }

  .icon-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
  }

  form {
    @media screen and (min-width: $desktop) {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    }
  }

  h2 {
    @media screen and (min-width: $desktop) {
      margin-bottom: 50px;
    }
  }

  .actions {
    @media screen and (min-width: $desktop) {
      margin-top: 80px;
    }
  }
}

.repeat-code {
  margin: 0 10px 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  color: rgba(34, 34, 34, 0.8);

  @media screen and (min-width: $desktop) {
    width: 335px;
    font-size: 15px;
    font-weight: 500;
  }

  div {
    padding: 18px 0;
    width: 60px;
    text-align: center;
    font-size: 24px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0 20px 40px 0 #cad9ef66;
    box-sizing: border-box;
    cursor: pointer;

    span:before {
      color: #015bb0;
    }
  }
}

.actions {
  @media screen and (min-width: $desktop) {
    margin-top: 40px;
    width: 335px;
  }

  .btn {
    margin: 0 auto;
    display: block;
    width: 255px;
    max-width: 100%;
  }
}
</style>
