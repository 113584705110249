<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { toast } from 'vue3-toastify'
import { api } from '@src/api'

const isLoading = ref(false)
const childLogin = ref<string>('')
const childPassword = ref<string>('')

const handleCopy = (value: string) => {
  toast.success('Скопировано')
  navigator.clipboard.writeText(value)
}

const handleGenerateNew = () => {
  if (childLogin.value !== '') {
    childLogin.value = ''
    childPassword.value = ''

    api.auth.generateAccountChild().then((data) => {
      childLogin.value = data.login
      childPassword.value = data.password
    })
  }
}

onMounted(() => {
  api.users.credentialChild().then((data) => {
    childLogin.value = data.login
    childPassword.value = data.password
  })
})
</script>

<template>
  <main>
    <div class="child">
      <div class="child--info">
        <div class="child--form">
          <img
            class="only-mobile"
            src="../../assets/img/img-child.gif"
            alt="Child"
          />

          <h4>Профиль ребенка создан</h4>

          <div class="child--field">
            <div class="child--field--name">
              Логин:
              <span class="child--field--value">{{ childLogin }}</span>
              <img
                v-if="childLogin === ''"
                src="../../assets/img/loading.svg"
                alt="Loading"
              />
            </div>

            <div
              class="child--field--copy icon-copy"
              @click="handleCopy(childLogin)"
            ></div>
          </div>

          <div class="child--field">
            <div class="child--field--name">
              Пароль:
              <span class="child--field--value">{{ childPassword }}</span>
              <img
                v-if="childPassword === ''"
                src="../../assets/img/loading.svg"
                alt="Loading"
              />
            </div>

            <div
              class="child--field--copy icon-copy"
              @click="handleCopy(childPassword)"
            ></div>
          </div>
        </div>

        <div class="child--sub">
          <div class="emotions-ok-hand"></div>
          <p>
            Используя эти данные для входа, Ваш ребенок сможет читать записи
            Дневника
          </p>
        </div>
      </div>

      <div class="actions">
        <div
          class="btn"
          :class="{ disabled: isLoading, loading: childLogin === '' }"
          @click="handleGenerateNew"
        >
          Сгенерировать новые
        </div>

        <p>старые логин и пароль будут недействительны</p>
      </div>
    </div>

    <div class="img-block only-desktop">
      <img
        src="../../assets/img/img-child.gif"
        alt="Child"
      />
    </div>
  </main>
</template>

<style scoped lang="scss">
main {
  padding-top: 35px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: calc(100dvh - 105px);
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    padding-top: 160px;
    align-items: center;
    min-height: 0;

    > div {
      flex: 0 0 560px;
    }
  }
}

.child {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;

  &--info {
    @media screen and (min-width: $desktop) {
      margin: 0 auto;
      width: 500px;
    }
  }

  &--form {
    padding: 0 20px 20px;
    background: #ffffff;
    border-radius: 30px;
    box-shadow: 0 20px 40px 0 #cad9ef66;

    @media screen and (min-width: $desktop) {
      padding: 40px;
    }

    img {
      margin: 0 auto;
      display: block;
      width: 200px;
    }

    h4 {
      text-align: center;

      @media screen and (min-width: $desktop) {
        margin-bottom: 50px;
        font-size: 32px;
      }
    }
  }

  &--field {
    margin-top: 15px;
    padding: 15px 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 20px 40px 0 #cad9ef66;

    @media screen and (min-width: $desktop) {
      margin-top: 30px;
    }

    &--value {
      font-weight: 700;
      color: #015bb0;
    }

    &--copy {
      font-size: 20px;
      cursor: pointer;
    }

    img {
      margin: -14px 0;
      display: inline-block;
      width: 40px;
    }
  }

  &--sub {
    margin: 20px 8px 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;

    @media screen and (min-width: $desktop) {
      margin: 30px 65px 0;
      gap: 15px;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.2;
    }

    .emotions-ok-hand {
      --size: 26;
      flex-shrink: 0;

      @media screen and (min-width: $desktop) {
        --size: 40;
      }
    }
  }
}

.actions {
  text-align: center;

  @media screen and (min-width: $desktop) {
    margin-top: 80px;
  }

  p {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    opacity: 0.8;

    @media screen and (min-width: $desktop) {
      margin-top: 30px;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.2;
    }
  }
}

.btn {
  margin: 0 auto;
  display: block;
  width: 255px;
  max-width: 100%;
}

.img-block {
  img {
    margin: 0 auto;
    display: block;
    width: 500px;
    height: 500px;
    max-width: 100%;
  }
}
</style>
