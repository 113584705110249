import { RouteRecordRaw } from 'vue-router'
import Index from '@src/pages/Index.vue'
import Login from '@src/pages/Login.vue'
import Register from '@src/pages/Register.vue'
import Search from '@src/pages/Search.vue'
import Contact from '@src/pages/Contact.vue'
import RestorePassword from '@src/pages/RestorePassword.vue'
import Google from '@src/pages/external/Google.vue'
import Apple from '@src/pages/external/Apple.vue'
import ShowUser from '@src/pages/ShowUser.vue'

const guestRoutes: RouteRecordRaw[] = [
  {
    path: '',
    component: Index,
    name: 'index',
    meta: {
      permission: 'guest',
    },
  },
  {
    path: 'login',
    component: Login,
    name: 'login',
    meta: {
      permission: 'guest',
    },
  },
  {
    path: 'login/google',
    component: Google,
    name: 'login.google',
    meta: {
      permission: 'guest',
    },
  },
  {
    path: 'login/apple',
    component: Apple,
    name: 'login.apple',
    meta: {
      permission: 'guest',
    },
  },
  {
    path: 'restore-password',
    component: RestorePassword,
    name: 'restore-password',
    meta: {
      permission: 'guest',
    },
  },
  {
    path: 'register',
    component: Register,
    name: 'register',
    meta: {
      permission: 'guest',
    },
  },
  {
    path: 'search',
    component: Search,
    name: 'search',
  },
  {
    path: 'users/:id(\\d+)',
    component: ShowUser,
    name: 'show-user',
  },
  {
    path: 'contact',
    component: Contact,
    name: 'contact',
  },
]

export default guestRoutes
