<script setup lang="ts">
import { inject, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { api } from '@src/api'
import { useSearchStore } from '@src/store/search.ts'
import FormText from '@src/components/form/FormText.vue'
import DiarySearch from '@src/components/DiarySearch.vue'
import { LocalEventBus } from '@src/event-bus.ts'

let timer: NodeJS.Timeout

const router = useRouter()
const eventBus = inject(LocalEventBus)

const searchStore = useSearchStore()
const search = ref(null)
const query = ref('')
const resultsLoading = ref(false)
const resultsTotal = ref<number | null>(null)
const results = ref<
  {
    id: number
    fullName: string
    avatar: string | null
    birthDate: string | null
    countDiaries: number
  }[]
>([])

const handleDiaryClick = (id: number) => {
  router.push({ name: 'show-user', params: { id } })
}

searchStore.$subscribe((_, state) => {
  query.value = state.query
  resultsTotal.value = state.resultsTotal

  if (searchStore.runSearch) {
    searchStore.runSearch = false

    resultsTotal.value = null
    resultsLoading.value = false
    results.value = []
    clearTimeout(timer)

    if (query.value.length >= 3) {
      resultsLoading.value = true

      timer = setTimeout(() => {
        api.users.search(query.value).then(({ total, result }) => {
          resultsTotal.value = total
          results.value = result
          resultsLoading.value = false
        })
      }, 1000)
    }
  }
})

watch(
  () => query.value,
  (query) => {
    searchStore.query = query
    searchStore.runSearch = true
  },
  {
    immediate: true,
  },
)

watch(
  () => resultsTotal.value,
  (resultsTotal) => {
    searchStore.resultsTotal = resultsTotal
  },
  {
    immediate: true,
  },
)

onMounted(() => {
  if (search.value !== null) {
    ;(search.value as { doFocus: () => void }).doFocus()
  }
})

onUnmounted(() => {
  eventBus?.emit('clearSearch', 'clear')
})
</script>

<template>
  <main :class="{ empty: resultsTotal === null && !resultsLoading }">
    <img
      src="../assets/img/img-search.gif"
      alt="Search"
    />

    <div class="results">
      <h1 class="only-desktop">Результаты</h1>

      <FormText
        ref="search"
        v-model="query"
        id="search"
        type="text"
        class="search-input only-mobile"
      />

      <div class="results--status only-mobile">
        <template v-if="query.length < 3">
          <span class="icon-search"></span>
          <span>введите 3 символа</span>
        </template>
        <template v-else-if="resultsTotal === 0">
          <span class="icon-error"></span>
          <span>к сожалению, ничего не найдено<br />повторите поиск</span>
        </template>
        <template v-else-if="resultsTotal !== null">
          <span class="icon-like"></span>
          <span>
            найдено {{ resultsTotal }}
            {{ $pluralize(resultsTotal, ['дневник', 'дневника', 'дневников']) }}
          </span>
        </template>
      </div>

      <div class="results--list">
        <DiarySearch
          v-for="item in results"
          :key="item.id"
          :item="item"
          @click="handleDiaryClick(item.id)"
        />

        <img
          v-if="resultsLoading"
          src="../assets/img/loading.svg"
          alt="Loading"
        />
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss">
main {
  padding-top: 25px;
  display: flex;
  flex-flow: column nowrap;
  min-height: calc(100dvh - 105px);
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    padding-top: 100px;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 0;

    &.empty {
      > img {
        margin-right: auto;
      }

      .results {
        display: none;
      }
    }

    > div {
      flex: 0 0 560px;
    }
  }

  > img {
    display: block;
    margin: 0 auto;
    width: 160px;

    @media screen and (min-width: $desktop) {
      margin-top: 80px;
      margin-right: 0;
      order: 2;
      width: 400px;
    }
  }
}

.search-input {
  padding-top: 20px;
}

.results {
  h1 {
    margin-bottom: 60px;
    text-align: center;
  }

  &--status {
    padding-top: 20px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 9px;
    font-size: 15px;

    [class^='icon-'] {
      font-size: 24px;
    }
  }

  &--list {
    margin: 20px -10px -30px;
    padding: 0 10px;
    height: calc(100dvh - 394px);
    box-sizing: border-box;
    overflow-y: scroll;
    scrollbar-width: thin;

    @media screen and (min-width: $desktop) {
      margin: 0 0 -30px;
      padding: 0 60px;
      height: calc(100dvh - 308px);
    }

    > img {
      margin: 20px auto;
      display: block;
      width: 50px;

      @media screen and (min-width: $desktop) {
        margin: 36px auto;
        width: 80px;
      }
    }
  }
}
</style>
