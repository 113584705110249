<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import Validator, { Yup } from '@src/validator.ts'
import { api } from '@src/api'
import FormText from '@src/components/form/FormText.vue'

const router = useRouter()

const isLoading = ref(false)
const step = ref(2)
const restoreToken = ref('')
const isCodeSent = ref(false)

const validator = new Validator()
const email = ref('')
const emailCode = ref('')
const password = ref('')
const repeatPassword = ref('')

const finishStep2 = () => {
  if (isCodeSent.value) {
    if (!isLoading.value) {
      isLoading.value = true

      validator.validate('email', 'emailCode').then((result) => {
        if (result) {
          api.auth
            .restorePasswordVerifyResetCode({
              email: email.value,
              code: emailCode.value.toUpperCase(),
            })
            .then(({ token }) => {
              restoreToken.value = token
              step.value = 3
            })
            .finally(() => {
              isLoading.value = false
            })
        } else {
          isLoading.value = false
        }
      })
    }
  } else {
    sendEmailCode()
  }
}

const finishStep3 = () => {
  if (password.value !== repeatPassword.value) {
    validator.setError('repeatPassword', 'пароли должны совпадать')
  } else {
    if (!isLoading.value) {
      isLoading.value = true

      validator.validate('password').then((result) => {
        if (result) {
          api.auth
            .restorePasswordResetPassword({
              newPassword: password.value,
              token: restoreToken.value,
            })
            .then(() => {
              router.push({ name: 'index' })
            })
            .finally(() => {
              isLoading.value = false
            })
        } else {
          isLoading.value = false
        }
      })
    }
  }
}

const sendEmailCode = () => {
  if (!isLoading.value) {
    isLoading.value = true

    validator.validate('email').then((result) => {
      if (result) {
        api.auth
          .restorePasswordSendResetCode({
            email: email.value,
          })
          .then(() => {
            isCodeSent.value = true
          })
          .finally(() => {
            isLoading.value = false
          })
      } else {
        isLoading.value = false
      }
    })
  }
}
</script>

<template>
  <main>
    <div
      v-if="step === 2"
      class="form-block"
    >
      <form ref="form2">
        <h2>Восстановление пароля</h2>

        <FormText
          v-model="email"
          :ref="(el: typeof FormText) => validator.addItem('email', el)"
          id="email"
          :rules="Yup.string().email().required()"
          type="email"
          label="Ваша почта:"
          :disabled="isCodeSent"
        />
        <FormText
          v-if="isCodeSent"
          v-model="emailCode"
          :ref="(el: typeof FormText) => validator.addItem('emailCode', el)"
          id="emailCode"
          :rules="Yup.string().required()"
          type="code"
          label="Код верификации Email:"
        />
      </form>

      <div class="actions">
        <div
          v-if="isCodeSent"
          class="repeat-code"
        >
          <p>
            Не пришел код?
            <br class="only-desktop" />
            Запросить повторно
          </p>

          <div
            :class="{ disabled: isLoading }"
            @click="sendEmailCode"
          >
            <span class="icon-refresh"></span>
          </div>
        </div>

        <div
          class="btn primary"
          :class="{ loading: isLoading }"
          @click="finishStep2"
        >
          {{ isCodeSent ? 'Продолжить' : 'Восстановить' }}
        </div>
      </div>
    </div>

    <div
      v-if="step === 3"
      class="form-block"
    >
      <form ref="form3">
        <h2>Новый пароль</h2>

        <FormText
          v-model="password"
          :ref="(el: typeof FormText) => validator.addItem('password', el)"
          id="password"
          :rules="Yup.string().required()"
          type="password"
          label="Придумайте пароль:"
        />
        <FormText
          v-model="repeatPassword"
          :ref="
            (el: typeof FormText) => validator.addItem('repeatPassword', el)
          "
          id="repeatPassword"
          type="password"
          label="Повторите пароль:"
        />
      </form>

      <div class="actions">
        <div
          class="btn primary"
          :class="{ loading: isLoading }"
          @click="finishStep3"
        >
          Сменить пароль
        </div>
      </div>
    </div>

    <div class="img-block only-desktop">
      <img
        src="../assets/img/img-restore.gif"
        alt="Register"
      />
    </div>
  </main>
</template>

<style scoped lang="scss">
main {
  padding-top: 25px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: calc(100dvh - 105px);
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    padding-top: 110px;
    align-items: center;
    min-height: 0;

    > div {
      flex: 0 0 560px;
    }
  }
}

.form-block {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  text-align: center;

  @media screen and (min-width: $desktop) {
    align-items: center;
  }

  h2 {
    margin-bottom: 20px;
    text-align: center;

    @media screen and (min-width: $desktop) {
      margin-bottom: 40px;
    }
  }

  form {
    img {
      margin: 0 auto 30px;
      display: block;
      width: 160px;
      max-width: 100%;
    }

    .field-wrapper {
      display: inline-block;
      width: 100%;

      @media screen and (min-width: $desktop) {
        width: 335px;
      }
    }
  }

  .actions {
    margin-top: 30px;

    @media screen and (min-width: $desktop) {
      margin-top: 60px;
    }
  }

  .btn {
    margin: 0 auto;
    display: block;
    width: 255px;
    max-width: 100%;
  }
}

.img-block {
  img {
    margin: 0 auto;
    display: block;
    width: 500px;
    height: 500px;
    max-width: 100%;
  }

  .text-mini {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 500;
  }
}

.alt-block {
  margin: 43px auto 0;
  width: 255px;
  max-width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 400;

  @media screen and (min-width: $desktop) {
    margin-top: 50px;
    width: 335px;
    font-size: 15px;
    font-weight: 500;
  }

  > p {
    color: rgba(34, 34, 34, 0.8);
  }
}

.repeat-code {
  margin: 0 10px 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  color: rgba(34, 34, 34, 0.8);

  @media screen and (min-width: $desktop) {
    width: 335px;
    font-size: 15px;
    font-weight: 500;
  }

  div {
    padding: 18px 0;
    width: 60px;
    text-align: center;
    font-size: 24px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0 20px 40px 0 #cad9ef66;
    box-sizing: border-box;
    cursor: pointer;

    span:before {
      color: #015bb0;
    }
  }
}

.text-mini {
  display: inline-flex;
  align-items: center;
}
</style>
